import React, {useState, useEffect} from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import AboutPageHeader from "../../components/aboutpageheader"
import ContactForm from "../../components/contactform"

import maniladata from "../../content/contactdetails.json"
import provincialdata from "../../content/contactdetailsprovincial.json"


import * as styles from "../../styles/pages/about/contact.module.css"

import imgsocialapp from "../../images/about/contact/social-app.png"
import imgsocialblog from "../../images/about/contact/social-blog.png"
import imgsocialfb from "../../images/about/contact/social-fb.png"
import imgsocialig from "../../images/about/contact/social-ig.png"
import imgsocialstudiob from "../../images/about/contact/social-studiob.png"
import imgsocialyoutube from "../../images/about/contact/social-youtube.png"

const imgconnectwithus = "../../images/about/contact/title.png"
const imgstayconnected = "../../images/about/contact/stayconnected.png"

const apiurl = "https://static.boysencdn.com/";


const contactfields = [
	{
		"name": "formname",
		"label": "Name",
		"value": "",
		"required": true,
	},
	{
		"name": "formphone",
		"label": "Contacts",
		"type": "tel",
		"value": "",
		"required": true,
	},

	{
		"name": "formemail",
		"label": "Email",
		"type": "email",
		"value": "",
		"required": true,
	},
	{
		"name": "formmsg",
		"label": "Message",
		"value": "",
		"type": "textarea",
		"required": true,
	}
];



const socialiconlist = [
	{
		"url": "https://www.facebook.com/BoysenPaintsPhilippines/",
		"name": "BoysenPaintsPhilippines",
		"img": imgsocialfb
	},
	{
		"url": "https://www.instagram.com/boysenpaintsphilippines/",
		"name": "@BoysenPaintsPhilippines",
		"img": imgsocialig
	},
	{
		"url": "https://www.youtube.com/user/BoysenPaintsPhil",
		"name": "BoysenPaintsPhil",
		"img": imgsocialyoutube
	},
	{
		"url": "https://www.myboysen.com/",
		"name": "Blog: myboysen.com",
		"img": imgsocialblog
	},
	{
		"url": "",
		"name": "Boysen App",
		"img": imgsocialapp
	},
	{
		"url": "https://www.studioboysen.com/",
		"name": "studioboysen.com",
		"img": imgsocialstudiob
	}
];

const AboutContactPage = () => {

	const [contactdata, setContactdata] = useState(maniladata);
	const [provincialcontactdata, setProvincialContactdata] = useState(provincialdata);

	useEffect(() => {
		fetch(apiurl+'contactdetails.json')
			.then(res => res.json())
			.then(data => setContactdata(data));
		fetch(apiurl+'contactdetailsprovincial.json')
			.then(res => res.json())
			.then(data => setProvincialContactdata(data));
	}, []);

	return (<Layout activePath='about/contact' pageTitle="Contact Us">
		<AboutPageHeader activemenu="about/contact" />
		<div className="text-centered">
			<h2 className={styles.titleconnect}>
				<StaticImage layout={"fullWidth"}  src={imgconnectwithus} alt={"Connect with us"} />
			</h2>

			<div className={styles.contactformholder}>
				<ContactForm
					submitURL={"https://api.boysencdn.com/contact/boysen"}
					fields={contactfields}
					hasClear={false}
				/>
			</div>
			<h2 className={styles.titlestayconnected}>
				<StaticImage layout={"fullWidth"}  src={imgstayconnected} alt={"Stay connected with us"} />
			</h2>
			<div className={styles.socialiconholder}>
				{socialiconlist.map((data,idx)=> {
					if (data.url.length < 1) {
						return <div
							className={styles.socialiconitem}
							title={data.name}
						>
							<img src={data.img} alt={data.name}  />
							<span className="textcolor-primary">{data.name}</span>
						</div>
					}
					return <a
							className={styles.socialiconitem}
							href={data.url}
							target="_blank"
							title={data.name}
							rel="noreferrer"
						>
							<img src={data.img} alt={data.name}  />
							<span className="textcolor-primary">{data.name}</span>
						</a>
				})}
			</div>

			<div className={styles.companytitle+" font-style-bold textcolor-primary"}>
				Pacific Paint (<span className="nobr futuraboldfont">BOYSEN<span className={styles.registeredicon+" registeredicon"}>&reg;</span></span>) Philippines, Inc.
			</div>

			<h2 className={"font-style-bold font-size-medium textcolor-primary "+styles.oursalesofficetitle}>
				OUR SALES OFFICE
			</h2>

			<div className={styles.detailsarea+" "+styles.detailsfontsize+" textcolor-primary"}>
				<div className={styles.hotlinesection}>
					<div className={"font-style-bold"}>Hotline:</div>
					{maniladata.main.hotline[0]}
				</div>
				<div className={styles.sectiontitle}>
					Metro Manila
				</div>
				<div className={styles.detailsholderrow}>
					<div className={styles.detailsholder+" "+styles.detailsholderbottom}>
						<h3 className={"font-style-bold textcolor-primary "+styles.detailsmaintitle}>Main Office:</h3>
						<ContactDetails object={contactdata.main} />
					</div>
					<div className={styles.detailsholder+" "+styles.detailsholderright+" "+styles.detailsholderbottom}>
						<h3 className={"font-style-bold textcolor-primary "+styles.detailsmaintitle}>Marketing &amp; Technical Office:</h3>
						<ContactDetails object={contactdata.marketing} />
					</div>
				</div>


				<div className={styles.sectiontitle}>
					Outside Metro Manila
				</div>
				<div className={styles.detailsholderrow}>
					<div className={styles.detailsholder}>
						<h3 className={"font-style-bold textcolor-primary "+styles.detailsmaintitle}>Legazpi Office & Showroom:</h3>
						<ContactDetails object={provincialcontactdata.legazpialbay} />
					</div>
					<div className={styles.detailsholder+" "+styles.detailsholderright}>
						<h3 className={"font-style-bold textcolor-primary "+styles.detailsmaintitle}>Cebu Office:</h3>
						<ContactDetails object={provincialcontactdata.mandauecebu} />
					</div>
				</div>
				<div className={styles.detailsholderrow}>
					<div className={styles.detailsholder}>
						<h3 className={"font-style-bold textcolor-primary "+styles.detailsmaintitle}>Iloilo Office:</h3>
						<ContactDetails object={provincialcontactdata.iloilopanay} />
					</div>
					<div className={styles.detailsholder+" "+styles.detailsholderright}>
						<h3 className={"font-style-bold textcolor-primary "+styles.detailsmaintitle}>Bacolod Office:</h3>
						<ContactDetails object={provincialcontactdata.mandalaganbacolod} />
					</div>
				</div>
				<div className={styles.detailsholderrow}>
					<div className={styles.detailsholder+" "+styles.detailsholderbottom}>
						<h3 className={"font-style-bold textcolor-primary "+styles.detailsmaintitle}>Davao Office:</h3>
						<ContactDetails object={provincialcontactdata.davaodavao} />
					</div>
					<div className={styles.detailsholder+" "+styles.detailsholderright+" "+styles.detailsholderbottom}>
						<h3 className={"font-style-bold textcolor-primary "+styles.detailsmaintitle}>Cagayan de Oro Office:</h3>
						<ContactDetails object={provincialcontactdata.cagayanmisamis} />
					</div>
				</div>
			</div>
		</div>
	</Layout>);
}


const ContactDetails = ({ object }) => {
	return (
		<>
		{	object.address &&
			<div className={styles.contactdetailaddress}>
				<div className={styles.contactdetailitems}>
					{object.address.map((data, index) => {
						return <div><span dangerouslySetInnerHTML={ {
							__html:
								data//.replace(/1115 Quezon City/g,"1115&nbsp;Quezon&nbsp;City")
							} } />
						</div>
						//return <div dangerouslySetInnerHTML={ {__html: data.replace(/1115/g,"<br/>1115")} } />
					})}
				</div>
			</div>
		}
		{ object.landline &&
			<ContactList
				phonenumber={true}
				textalignclass={""}
				object={object.landline}
				title="Telephone Numbers"
				separator={<br/>}
				classname={styles.contactdetaillandline} />
		}
		{ object.fax &&
			<ContactList
				phonenumber={true}
				textalignclass={""}
				object={object.fax}
				title="Fax Numbers"
				separator={<br/>}
				classname={styles.contactdetailfax} />
		}
		{ object.telefax &&
			<ContactList
				phonenumber={true}
				textalignclass={""}
				object={object.telefax}
				title="Telefax Numbers"
				separator={<br/>}
				classname={styles.contactdetailtelefax} />
		}
		{ object.mobile &&
			<ContactList
				phonenumber={true}
				textalignclass={""}
				object={object.mobile}
				title="Mobile Number"
				separator={<br/>}
				classname={styles.contactdetailmobile} />
		}
		{ object.email &&
			<ContactList
				phonenumber={false}
				textalignclass={""}
				object={object.email}
				title="Email"
				separator={<br/>}
				classname={styles.contactdetailemail} />
		}
		{ object.website &&
			<ContactWebsite
				phonenumber={false}
				textalignclass={""}
				object={object.website}
				title="Website"
				separator={<br/>}
				classname={styles.contactdetailwebsite} />
		}
		</>
	)
}

const ContactList = ({ textalignclass, title, separator, classname, object, phonenumber }) => {
	return (
		<div className={styles.contactdetail + " "+textalignclass+ " " + classname}>
			{title.length>0? <h4 className={styles.contactdetailtitle}>{title}</h4>:""}
			<div className={styles.contactdetailitems}>
				{object.map((data, index) => {
					if (phonenumber === false) {
						return <span>{index>0?separator:""}{data}</span>
					} else {
						var telnum = data;
						if (telnum.indexOf(" to ")>=0) {
							telnum = telnum.substring(0, telnum.indexOf(" to "));
						}
						telnum = telnum.replace(/[^0-9]/g, '');
						telnum = "+63"+telnum.substring(1);
						return <a className={"textcolor-primary "+styles.detailsfontsize} href={"tel:"+telnum}>{index>0?separator:""}{data}</a>
					}
				})}
			</div>
		</div>
	)
}

const ContactWebsite = ({ textalignclass, title, separator, classname, object }) => {
	return <></>
	/*
	return (
		<div className={styles.contactdetail + " "+textalignclass+" " + classname}>
			{title.length>0?title+": ":""}
			{object.map((data, index) => {
				return <span>{index>0?separator:""}<a className={"font-size-small"} href={"https://"+data} target="_blank" rel="noreferrer">{data}</a></span>
			})}
		</div>
	)
	*/
}


export default AboutContactPage
